import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Container from 'components/Container';
import Layout from 'components/Layout';
import MarkdownContainer from 'components/MarkdownContainer';
import Nav from 'components/Nav';
import SEO from 'components/SEO';

import useText from 'pageComponents/about/useText';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.serif};
  text-align: center;
  margin-bottom: 30px;
  ${({ theme }) => theme.sizes.f2};
`;

export const TextWrapper = styled.div`
  margin: 80px auto;
  max-width: 700px;
`;

function About({ data, location }) {
  const html = useText();
  const { pageTitle, pageDescription, seoImage } = data.contentfulPageSeo;
  const seo = {
    description: pageDescription,
    imageUrl: seoImage.file.url,
    title: pageTitle,
  };
  return (
    <Layout>
      <SEO {...seo} />
      <Nav location={location} navTheme="dark" />
      <Container>
        <TextWrapper>
          <Title>About</Title>
          <MarkdownContainer dangerouslySetInnerHTML={{ __html: html }} />
        </TextWrapper>
      </Container>
    </Layout>
  );
}

About.propTypes = {};

About.defaultProps = {};

export const query = graphql`
  query AboutPageQuery {
    contentfulPageSeo(identifier: { eq: "about" }) {
      pageTitle
      pageDescription
      seoImage {
        file {
          url
        }
      }
    }
  }
`;

export default About;
