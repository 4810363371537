import { useStaticQuery, graphql } from 'gatsby';

function useText() {
  const { html } = useStaticQuery(
    query
  ).contentfulTextFields.text.childMarkdownRemark;
  return html;
}

export default useText;

const query = graphql`
  query AboutMain {
    contentfulTextFields(identifier: { eq: "about-main" }) {
      id
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
