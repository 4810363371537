import styled from 'styled-components';

const MarkdownContainer = styled.div`
  ${({ theme }) => theme.sizes.p}

  p {
    margin: 10px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fonts.serif};
    text-align: center;
  }

  h1 {
    ${({ theme }) => theme.sizes.f1};
  }

  h2 {
    ${({ theme }) => theme.sizes.f2};
  }

  h3 {
    ${({ theme }) => theme.sizes.f3};
  }

  blockquote {
    background-color: rgba(0, 0, 0, 0.02);
    border-left: 2px solid rgba(0, 0, 0, 0.7);
    margin: 30px auto;
    padding: 1rem 30px 1rem 40px;
    position: relative;
  }

  blockquote p {
    line-height: 1.6;
    font-weight: 350;
    font-family: 'Muli';
    font-style: italic;
  }

  blockquote p cite {
    display: block;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fonts.serif};
    font-style: normal;
    padding-top: 6px;
  }

  // image styles
  .gatsby-resp-image-image {
    object-fit: cover;
  }
  .gatsby-resp-image-link {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export default MarkdownContainer;
